<template>
  <div
    v-if="$store.state.fastUsuarioAdminFast"
  >
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Admin Fast</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div>
        <div class="container">
          <div class="row">
            <div class="col-12 mb-4">
              <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon" />
                </button>
                <div
                  id="navbarNav"
                  class="collapse navbar-collapse"
                >
                  <ul class="navbar-nav">
                    <li :class="fastAdminPaginaAtual == 'plataformas' ? 'nav-item active' : 'nav-item'"> 
                      <a
                        class="nav-link"
                        href="#"
                        @click.prevent="fastAdminNavegacao('plataformas')"
                      >Gerenciar plataformas</a>
                    </li>
                    <li :class="fastAdminPaginaAtual == 'usuarios' ? 'nav-item active' : 'nav-item'">
                      <a
                        class="nav-link"
                        href="#"
                        @click.prevent="fastAdminNavegacao('usuarios')"
                      >Lista de administradores</a>
                    </li>
                    <li :class="fastAdminPaginaAtual == 'certificadores' ? 'nav-item active' : 'nav-item'">
                      <a
                        class="nav-link"
                        href="#"
                        @click.prevent="fastAdminNavegacao('certificadores')"
                      >Lista de certificadores</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <div
            v-if="fastAdminPaginaAtual == 'plataformas'"
            class="row"
          >           
            <div class="col-12 text-center mb-4">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                  <label>Filtrar pelo nome:</label>
                  <input
                    v-model="filtros.nome_plataforma"
                    type="text"
                    class="form-control"
                    @keyup="aplicaFiltroGeral()"
                  >
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                  <label>Filtrar por status:</label>
                  <select
                    v-model="filtros.status"
                    class="form-control"
                    @change="aplicaFiltroGeral()"
                  >
                    <option
                      value=""
                      selected
                    >
                      Todos
                    </option>
                    <option value="A">
                      Somente ativadas
                    </option>
                    <option value="C">
                      Somente canceladas
                    </option>
                    <option value="T">
                      Somente em modo teste
                    </option>
                  </select>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                  <label>Módulo secretaria:</label>
                  <select
                    v-model="filtros.secretaria"
                    class="form-control"
                    @change="aplicaFiltroGeral()"
                  >
                    <option
                      value=""
                      selected
                    >
                      Todos
                    </option>
                    <option value="S">
                      Somente com módulo ativo
                    </option>
                    <option value="N">
                      Somente sem módulo ativo
                    </option>
                  </select>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                  <label>Representante:</label>
                  <select
                    v-model="filtros.representante"
                    class="form-control"
                    @change="aplicaFiltroGeral()"
                  >
                    <option
                      value=""
                      selected
                    >
                      Todos
                    </option>
                    <option value="S">
                      Somente representante
                    </option>
                    <option value="N">
                      Somente não representante
                    </option>
                  </select>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                  <label>Plataforma de venda:</label>
                  <select
                    v-model="filtros.venda"
                    class="form-control"
                    @change="aplicaFiltroGeral()"
                  >
                    <option
                      value=""
                      selected
                    >
                      Todos
                    </option>
                    <option value="S">
                      Somente com venda ativa
                    </option>
                    <option value="N">
                      Somente sem venda ativa
                    </option>
                  </select>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                  <label>Ordenar por:</label>
                  <select
                    v-model="filtros.ordenar"
                    class="form-control"
                    @change="aplicaFiltroGeral()"
                  >
                    <option
                      value="nome_plataforma"
                      selected
                    >
                      Nome da plataforma
                    </option>
                    <option value="data_cadastro_plataforma_asc">
                      Mais nova
                    </option>
                    <option value="data_cadastro_plataforma_desc">
                      Mais antiga
                    </option>
                  </select>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                  <label class="d-block">Ações</label>
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    @click="limparFiltro(); aplicaFiltroGeral();"
                  >
                    Limpar filtro
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
              <h3>Total ({{ fastPlataformasFiltro.length }})</h3>
            </div>
            <div
              v-for="p in fastPlataformasFiltro"
              :key="p.id_plataforma"
              class="col-sm-12 col-md-12 col-lg-4 mb-4"
            >
              <div class="card">
                <h5 class="card-header text-center">
                  #{{ p.id_plataforma + " - " + p.nome_plataforma }}
                </h5>
                <img
                  v-if="p.logo_plataforma"
                  class="card-img-top"
                  :src="p.logo_plataforma"
                >
                <img
                  v-else
                  class="card-img-top"
                  :src="require('@/assets/images/app/plataforma-img-02.jpg')"
                >
                <div class="card-body">
                  <div>
                    Data de cadastro: <span>{{ dataFormatada(p.data_cadastro_plataforma) }}</span>
                  </div>
                  <div
                    v-if="p.status == 'T'"
                    class="card-text"
                  >
                    Status: <span class="text-warning">Modo teste</span>
                  </div>
                  <div
                    v-else-if="p.status == 'A'"
                    class="card-text"
                  >
                    Status: <span class="text-success">Ativada</span>
                  </div>
                  <div
                    v-else-if="p.status == 'C'"
                    class="card-text"
                  >
                    Status: <span class="text-danger">Cancelada</span>
                    <br>
                    Data do cancelamento: <span class="text-danger">{{ formataDataT(p.data_cancelada) }}</span>
                  </div>
                  <div>
                    Id_pessoa: 
                    <span
                      v-if="p.id_pessoa"
                      class="text-success"
                    >{{ p.id_pessoa }}</span>
                    <span
                      v-else
                      class="text-danger"
                    >Não configurado</span>
                  </div>
                  <div>
                    Licenças: 
                    <span
                      v-if="p.licenca"
                      class="text-success"
                    >{{ p.licenca }}</span>
                    <span
                      v-else
                      class="text-danger"
                    >0</span>
                  </div>
                  <div>
                    Módulo secretaria: 
                    <span
                      v-if="p.modulo_secretaria == 'S'"
                      class="text-success"
                    >Sim</span>
                    <span
                      v-else
                      class="text-danger"
                    >Não</span>
                  </div>
                  <div>
                    Representante: 
                    <span
                      v-if="p.plataforma_representante == 'S'"
                      class="text-success"
                    >Sim</span>
                    <span
                      v-else
                      class="text-danger"
                    >Não</span>
                  </div>
                  <div>
                    Venda: 
                    <span
                      v-if="p.plataforma_venda == 'S'"
                      class="text-success"
                    >Sim</span>
                    <span
                      v-else
                      class="text-danger"
                    >Não</span>
                  </div>
                  <div>
                    SSO token: 
                    <span
                      v-if="p.sso_token"
                      class="text-success"
                    >{{ p.sso_token }}</span>
                    <span
                      v-else
                      class="text-danger"
                    >Não</span>
                  </div>
                  <div>
                    SSO URL: 
                    <span
                      v-if="p.sso_url"
                      class="text-success"
                    >{{ p.sso_url }}</span>
                    <span
                      v-else
                      class="text-danger"
                    >Não</span>
                  </div>
                  <div>
                    Checkout token: 
                    <span
                      v-if="p.sso_token"
                      class="text-success"
                    >{{ p.checkout_token }}</span>
                    <span
                      v-else
                      class="text-danger"
                    >Não</span>
                  </div>
                  <div>
                    Checkout URL: 
                    <span
                      v-if="p.sso_url"
                      class="text-success"
                    >{{ p.checkout_url }}</span>
                    <span
                      v-else
                      class="text-danger"
                    >Não</span>
                  </div>
                  <div class="mt-4 text-center">
                    <button
                      class="btn btn-sm btn-primary"
                      @click="exibeModalEditarPlataforma(p)"
                    >
                      Editar parâmetros
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="fastAdminPaginaAtual == 'usuarios'"
            class="row"
          >           
            <div class="col-12 text-center mb-4">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <h3>Administradores do FastEAD </h3>
                </div>
                <div
                  v-for="(u, index) in fastUsuariosAdmin"
                  :key="index"
                  class="col-sm-12 col-md-12 col-lg-4 mb-4"
                >
                  <div class="card">
                    <h5 class="card-header text-center">
                      #{{ u.id_usuario + " - " }}
                      <span v-if="u.nome_usuario">{{ u.nome_usuario }}</span>
                      <span
                        v-else
                        class="text-danger"
                      >Sem nome cadastrado</span>
                    </h5>
                    <div
                      class="card-img-top mt-2"
                      :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(u.image) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }"
                    />
                    <div class="card-body text-left">
                      <div>
                        Id_plataforma:
                        <span v-if="u.id_plataforma">{{ u.id_plataforma }}</span>
                        <span
                          v-else
                          class="text-danger"
                        >Nulo</span>
                      </div>
                      <div>
                        Id_usuario: 
                        <span v-if="u.id_usuario">{{ u.id_usuario }}</span>
                        <span
                          v-else
                          class="text-danger"
                        >Nulo</span>
                      </div>
                      <div>
                        Id_pessoa: 
                        <span v-if="u.id_pessoa">{{ u.id_pessoa }}</span>
                        <span
                          v-else
                          class="text-danger"
                        >Nulo</span>
                      </div>
                      <div>
                        Email:
                        <span v-if="u.email">{{ u.email }}</span>
                        <span
                          v-else
                          class="text-danger"
                        >Nulo</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="fastAdminPaginaAtual == 'certificadores'"
            class="row"
          >
            <div class="col-12">
              <div
                v-if="exibeTelasCertificadores.certificadores"
                class="row mt-4"
              >
                <div class="col-12 mt-2">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <h6 class="m-0 font-weight-bold text-primary">
                            Lista de certificadores
                          </h6>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 text-right" />
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr class="text-center">
                                <th>
                                  <small class="font-weight-bold text-center">Plataforma</small>
                                </th>
                                <th>
                                  <small class="font-weight-bold text-center">Nome contato</small>
                                </th>
                                <th>
                                  <small class="font-weight-bold text-center">Data do contrato</small>
                                </th>                               
                                <th>
                                  <small class="font-weight-bold text-center">Status</small>
                                </th>
                                <th class="font-weight-bold text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastSecretariaCertificadoresLoading">
                              <tr>
                                <td
                                  colspan="5"
                                  class="text-center"
                                >
                                  Carregando certificadores...
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else-if="fastSecretariaCertificadores.length">
                              <tr
                                v-for="(certificador, index) in fastSecretariaCertificadores"
                                :key="index"
                              >
                                <td class="align-middle text-center">
                                  {{ certificador.nome_plataforma }}
                                </td>  
                                <td class="align-middle text-center">
                                  {{ certificador.nome_contato }}
                                </td>   
                                <td class="align-middle text-center">
                                  <span v-if="certificador.data_contrato">{{ certificador.data_contrato.split("T")[0].split("-")[2] + "/" + certificador.data_contrato.split("T")[0].split("-")[1] + "/" + certificador.data_contrato.split("T")[0].split("-")[0] }}</span>
                                  <span
                                    v-else
                                    class="text-warning"
                                  >
                                    Pendente
                                  </span>
                                </td>                                 
                                <td class="align-middle text-center">
                                  <span
                                    v-if="certificador.ativo"
                                    class="btn btn-sm btn-success pt-0 pb-0"
                                  >Ativado</span>
                                  <span
                                    v-else
                                    class="btn btn-sm btn-danger pt-0 pb-0"
                                  >Desativado</span>
                                </td>                         
                                <td class="text-center align-middle">                                  
                                  <button
                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                    @click="exibeModalEditarSecretariaCertificador(certificador)"
                                  >
                                    <small>Editar</small>
                                  </button>
                                  <button
                                    class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                                    @click="exibeModalEditarCursos(certificador)"
                                  >
                                    <small>Cursos</small>
                                  </button>                                  
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="5"
                                  class="text-center"
                                >
                                  Nenhum certificador registrado
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else-if="exibeTelasCertificadores.cursos"
                class="row mt-4"
              >
                <div class="col-12 mb-4">
                  <h1>Plataforma {{ fastSecretariaCertificadorEditar.nome_plataforma }}</h1>
                  <h3>{{ fastSecretariaCertificadorEditar.nome_contato }}</h3>                  
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <h4>Total de cursos ({{ fastSecretariaCertificadorCursos.length }})</h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                  <button
                    class="btn btn-sm btn-primary"
                    @click="showModal('modalMeusCursos')"
                  >
                    + Adicionar curso
                  </button>
                </div>
                <div class="col-12 mt-2">
                  <div
                    v-if="fastSecretariaCertificadorCursosLoading"
                    class="row justify-content-around text-center"
                  >
                    <p>Carregando...</p>
                  </div>
                  <div
                    v-else-if="fastSecretariaCertificadorCursos.length"
                    class="row"
                  >
                    <div
                      v-for="curso in fastSecretariaCertificadorCursos"
                      :key="curso.id_secretaria_curso"
                      class="col-sm-12 col-md-12 col-lg-4 mb-4"
                    >
                      <div class="card">
                        <div class="card-header text-center">
                          <b class="card-title">{{ curso.nome_curso }}</b>
                        </div>
                        <img
                          v-if="curso.logo_curso"
                          class="card-img-top"
                          :src="curso.logo_curso"
                          alt="Logo do curso"
                        >
                        <img
                          v-else
                          class="card-img-top"
                          :src="require('@/assets/images/app/plataforma-img.jpg')"
                          alt="Logo do curso"
                        >
                        <div class="card-body text-center">   
                          <button
                            class="btn btn-sm btn-primary ml-1 mr-1"
                            @click="exibeModalSecretariaCertificadorAnexo(curso)"
                          >
                            <small>
                              Anexo 
                              <span
                                v-if="curso.path_portaria && curso.path_portaria != '0'"
                                class="badge badge-light"
                              >1</span> 
                              <span
                                v-else
                                class="badge badge-light"
                              >0</span>
                            </small>                              
                          </button>
                          <button
                            class="btn btn-sm btn-primary ml-1 mr-1"
                            @click="exibeModalSecretariaCertificadorEstado(curso)"
                          >
                            <small>
                              Estados <span class="badge badge-light">{{ curso.total_estados }}</span>
                            </small>                            
                          </button>
                          <button
                            class="btn btn-sm btn-primary ml-1 mr-1"
                            @click="exibeModalSecretariaCertificadorPreco(curso)"
                          >
                            <small>
                              Preços <span class="badge badge-light">{{ curso.total_precos }}</span>
                            </small>
                          </button> 
                        </div>
                        <div class="card-footer text-center">
                          <button
                            class="btn btn-sm btn-danger pt-0 pb-0"
                            @click="exibeModalExcluirSecretariaCertificadorCurso(curso)"
                          >
                            <small>- Remover curso</small>                    
                          </button>
                        </div>
                      </div>
                    </div>                              
                  </div>
                  <div
                    v-else
                    class="row justify-content-around text-center"
                  >
                    <p>Nenhum curso cadastrado</p>
                  </div> 
                </div>
                <div class="col-12">
                  <button
                    class="btn btn-red-hollow"
                    @click="exibeTelasCertificadores.cursos = false; exibeTelasCertificadores.certificadores = true"
                  >
                    <b-icon-arrow-return-left />
                    Voltar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- Modals -->
    <modal
      name="modalEditarPlataforma"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar plataforma</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarPlataforma')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Status da plataforma</label>
              <select
                v-model="model.fastPlataforma.status"
                class="form-control"
              >
                <option value="A">
                  Ativada
                </option>
                <option value="C">
                  Cancelada
                </option>
                <option value="T">
                  Modo teste
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Permissão de venda</label>
              <select
                v-model="model.fastPlataforma.plataforma_venda"
                class="form-control"
              >
                <option value="S">
                  Sim
                </option>
                <option value="N">
                  Não
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Representante</label>
              <select
                v-model="model.fastPlataforma.plataforma_representante"
                class="form-control"
              >
                <option value="S">
                  Sim
                </option>
                <option value="N">
                  Não
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
              <label>Módulo secretaria</label>
              <select
                v-model="model.fastPlataformaParametro.modulo_secretaria"
                class="form-control"
              >
                <option value="S">
                  Sim
                </option>
                <option value="N">
                  Não
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>SSO token</label>
              <input
                v-model="model.fastPlataformaParametro.sso_token"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>SSO url</label>
              <input
                v-model="model.fastPlataformaParametro.sso_url"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Checkout token</label>
              <input
                v-model="model.fastPlataformaParametro.checkout_token"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Checkout url</label>
              <input
                v-model="model.fastPlataformaParametro.checkout_url"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <div
                v-if="model.fastPlataformaParametro.pessoa.id_pessoa"
                class="row"
              >
                <div class="col-12 text-center">
                  <b>Pessoa responsável</b>
                  <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(model.fastPlataformaParametro.pessoa.image) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />
                  <div>
                    Id_pessoa: {{ model.fastPlataformaParametro.pessoa.id_pessoa }}
                  </div>
                  <div>
                    {{ model.fastPlataformaParametro.pessoa.first_name + " " + model.fastPlataformaParametro.pessoa.last_name }}
                  </div>
                  <div>
                    {{ model.fastPlataformaParametro.pessoa.email }}
                  </div>
                </div>
              </div>
            </div>  
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
              <label>Buscar pessoa</label>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-8">
                  <input
                    v-model="model.fastPlataformaParametro.pessoa.email"
                    type="email"
                    class="form-control form-control-lg"
                    placeholder="Informe o email da pessoa"
                  >
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 p-2 text-center">
                  <button
                    class="btn btn-primary"
                    @click.prevent="getPessoaResponsavel()"
                  >
                    Buscar pessoa
                  </button>
                </div>
              </div>
            </div>         
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="editarFastPlataforma()"
                >
                  Confirmar alterações
                </button>
                <button
                  type="button"
                  class="btn btn-secondary ml-2"
                  @click="cancelarFastPlataforma()"
                >
                  Cancelar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarSecretariaCertificador"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar certificador</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarSecretariaCertificador')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nome contato</label>
              <input
                :value="fastSecretariaCertificadorEditar.nome_contato"
                type="text"
                class="form-control"
                maxlength="100"
                readonly
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Fone contato</label>
              <input
                v-mask="'(##) #########'"
                :value="fastSecretariaCertificadorEditar.fone_contato"
                type="text"
                class="form-control"
                maxlength="20"
                readonly
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Data do contrato</label>
              <input
                v-model="fastSecretariaCertificadorEditar.data_contrato"
                type="date"
                class="form-control"                
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Data da ativação do contrato</label>
              <input
                v-model="fastSecretariaCertificadorEditar.data_ativacao_contrato"
                type="date"
                class="form-control"                
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Data do encerramento do contrato</label>
              <input
                v-model="fastSecretariaCertificadorEditar.data_encerramento_contrato"
                type="date"
                class="form-control"                
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2 text-center">
              <label>Ativo</label>              
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                      <div class="col-12 text-center">
                        <label class="switch">
                          <input
                            v-model="fastSecretariaCertificadorEditar.ativo"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>      
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2 text-center">
              <b class="text-danger">O cliente certificador só poderá alterar as configurações quando o status estiver desativado.</b>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarSecretariaCertificador()"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirSecretariaCertificadorCurso"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Excluir curso do certificador?</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirSecretariaCertificadorCurso')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mb-4">
              <h6>{{ fastSecretariaCertificadorCursoEditar.nome_curso }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger mr-2"
                  @click.prevent="excluirSecretariaCertificadorCurso()"
                >
                  Confirmar
                </button>
                <button
                  href="#"
                  type="button"
                  class="btn btn-secondary mr-2"
                  @click.prevent="hideModal('modalExcluirSecretariaCertificadorCurso')"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>          
        </div>
      </div>
    </modal>

    <modal
      name="modalSecretariaCertificadorAnexo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Anexo</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalSecretariaCertificadorAnexo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div v-if="fastSecretariaCertificadorCursoEditar.path_portaria && fastSecretariaCertificadorCursoEditar.path_portaria != '0'">
            <a
              :href="fastSecretariaCertificadorCursoEditar.path_portaria"
              target="_blank"
              download
            >
              Download do anexo
            </a>
          </div>
          <h6 v-else>
            Sem anexo
          </h6>
        </div>
      </div>
    </modal>

    <modal
      name="modalMeusCursos"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="ol-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Lista de cursos da plataforma {{ fastSecretariaCertificadorEditar.nome_plataforma }}</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalSecretariaCertificadorCursos', 'modalMeusCursos')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-3 mb-1">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Filtro por nome do curso"
                @keyup="filtroNomeCurso"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-2 mb-1">
              <select
                v-model="filtroNivelEscolhido"
                class="form-control form-control-sm"
                @change="aplicaFiltroCursos()"
              >
                <option value="">
                  -- Filtrar por nível --
                </option>
                <option
                  v-for="(nivel, index) in filtroNiveis"
                  :key="index"
                  :value="nivel"
                >
                  <span>{{ retornaCursoNivel(nivel) }}</span>
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Nome do curso</small>
                    </th>
                    <th>
                      <small class="font-weight-bold">Nível</small>                                  
                    </th>
                    <th class="text-center">
                      <small
                        class="font-weight-bold"
                      >Carga horária</small>
                    </th>
                    <th class="text-center">
                      <small
                        class="font-weight-bold"
                      >Preço</small>
                    </th>
                    <th class="text-center">
                      <small
                        class="font-weight-bold"
                      >Publicado</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastSecretariaCursosPaginacao.length">
                  <tr
                    v-for="curso in fastSecretariaCursosPaginacao"
                    :key="curso.id_curso"
                  >
                    <td class="align-middle">
                      <small>{{ curso.nome_curso }}</small>  
                    </td>  
                    <td class="align-middle">
                      <small>{{ retornaCursoNivel(curso.nivel) }}</small> 
                    </td>  
                    <td class="align-middle text-center">
                      <span v-if="curso.carga_horaria">
                        <small>{{ curso.carga_horaria }}h</small>                                    
                      </span>                                  
                    </td> 
                    <td class="align-middle text-center">
                      <small v-if="curso.preco">R$ {{ formataPreco(curso.preco) }}</small>
                      <small
                        v-else
                        class="text-danger"
                      >Indefinido</small>                           
                    </td> 
                    <td class="align-middle text-center">
                      <span
                        v-if="curso.publicado"
                        class="btn btn-sm btn-success pt-0 pb-0"
                      >
                        <small>Sim</small>
                      </span>
                      <span
                        v-else
                        class="btn btn-sm btn-danger pt-0 pb-0"
                      >
                        <small>Não</small>
                      </span>
                    </td>   
                    <td class="align-middle text-center">
                      <span
                        v-if="!verificaCursoJaInserido(curso.id_secretaria_curso)"
                        class="btn btn-sm btn-success text-white pt-0 pb-0"
                      >
                        <small @click="inserirSecretariaCertificadorCurso(curso)">+ Adicionar curso</small>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="5"
                    >
                      Nenhum curso encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="10"
                :items="fastSecretariaCursosFiltro"
                @changePage="fastSecretariaCursosPaginacao = $event"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="showModal('modalSecretariaCertificadorCursos', 'modalMeusCursos')"
              >
                Voltar
              </a>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalSecretariaCertificadorEstado"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Estados</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalSecretariaCertificadorEstado')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 text-right">
              <button
                v-if="fastSecretariaCertificadorEstados.length < 27"
                class="btn btn-sm btn-primary"
                @click="showModal('modalListaEstados', 'modalSecretariaCertificadorEstado')"
              >
                <small>+ Adicionar estado</small>
              </button>
            </div>
          </div>
          <div class="table-responsive mt-2">
            <table class="table table-sm table-striped">
              <thead class="thead-dark">
                <tr class="text-center">
                  <th>Estado</th>
                  <th>Ações</th>
                </tr>
              </thead>     
              <tbody v-if="fastSecretariaCertificadorEstadosLoading">
                <tr class="text-center">
                  <td colspan="2">
                    Carregando estados...
                  </td>
                </tr>
              </tbody>      
              <tbody v-else-if="fastSecretariaCertificadorEstados.length">
                <tr
                  v-for="estado in fastSecretariaCertificadorEstados"
                  :key="estado.uf"
                  class="text-center"
                >
                  <td>{{ retornaEstadoNome(estado.uf) }}</td>
                  <td>
                    <button
                      class="btn btn-sm btn-danger pt-0 pb-0"
                      @click="excluirSecretariaCertificadorEstado(estado.uf)"
                    >
                      <small>- Remover</small>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="text-center">
                  <td colspan="2">
                    Nenhum estado registrado
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalListaEstados"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Lista de estados</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalSecretariaCertificadorEstado', 'modalListaEstados')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 text-right">
              <button
                class="btn btn-sm btn-success pt-0 pb-0"
                @click="inserirSecretariaCertificadorEstado('00')"
              >
                <small>+ Adicionar todos</small>
              </button>
            </div>
          </div>
          <div class="table-responsive mt-2">
            <table class="table table-sm table-striped">
              <thead class="thead-dark">
                <tr class="text-center">
                  <th>Estado</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="estado in listaEstados"
                  :key="estado.sigla"
                  class="text-center"
                >
                  <td>{{ estado.nome }}</td>
                  <td>
                    <button
                      v-if="!verificaEstadoJaInserido(estado.sigla)"
                      class="btn btn-sm btn-success pt-0 pb-0"
                      @click="inserirSecretariaCertificadorEstado(estado.sigla)"
                    >
                      <small>+ Adicionar</small>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                class="btn btn-secondary"
                @click="showModal('modalSecretariaCertificadorEstado', 'modalListaEstados')"
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalSecretariaCertificadorPreco"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Preços</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalSecretariaCertificadorPreco')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 text-right">
              <button
                class="btn btn-sm btn-primary"
                @click="showModal('modalCriarSecretariaCertificadorPreco', 'modalSecretariaCertificadorPreco')"
              >
                <small>+ Adicionar preço</small>
              </button>
            </div>
          </div>
          <div class="table-responsive mt-2">
            <table class="table table-sm table-striped">
              <thead class="thead-dark">
                <tr class="text-center">
                  <th>Quantidade mensal</th>
                  <th>Preço</th>
                  <th>Comissão FastEAD</th>
                  <th>Dias de repasse</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody v-if="fastSecretariaCertificadorPrecosLoading">
                <tr class="text-center">
                  <td colspan="5">
                    Carregando preços...
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="fastSecretariaCertificadorPrecos.length">
                <tr
                  v-for="(preco, index) in fastSecretariaCertificadorPrecos"
                  :key="index"
                  class="text-center"
                >
                  <td>{{ preco.qtde_mensal }}</td>
                  <td>
                    <span v-if="preco.preco">R$ {{ formataPreco(preco.preco) }}</span>
                  </td>
                  <td>
                    <span v-if="preco.comissao_fastead">R$ {{ formataPreco(preco.comissao_fastead) }}</span>
                  </td>
                  <td>{{ preco.dias_repasse_pagto }}</td>
                  <td>
                    <button
                      class="btn btn-sm btn-primary ml-1 mr-1"
                      @click="exibeModalEditarSecretariaCertificadorPreco(preco)"
                    >
                      <small>Editar</small>
                    </button>
                    <button
                      class="btn btn-sm btn-danger ml-1 mr-1"
                      @click="exibeModalExcluirSecretariaCertificadorPreco(preco)"
                    >
                      <small>Excluir</small>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="text-center">
                  <td colspan="5">
                    Nenhum preço registrado
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarSecretariaCertificadorPreco"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Novo registro de preço</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalSecretariaCertificadorPreco', 'modalCriarSecretariaCertificadorPreco')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Quantidade mensal</label>
              <input
                v-model="fastSecretariaCertificadorPreco.qtde_mensal"
                type="number"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Dias de repasse</label>
              <input
                v-model="fastSecretariaCertificadorPreco.dias_repasse_pagto"
                type="number"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Preço</label>
              <input
                v-model="fastSecretariaCertificadorPreco.preco"
                type="number"
                min="1"
                step="any"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Comissão FastEAD</label>
              <input
                v-model="fastSecretariaCertificadorPreco.comissao_fastead"
                type="number"
                min="1"
                step="any"
                class="form-control"
              >
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <button
                class="btn btn-sm btn-primary ml-1 mr-1"
                @click="insereSecretariaCertificadorPreco()"
              >
                Cadastrar
              </button>
              <button
                class="btn btn-sm btn-secondary ml-1 mr-1"
                @click="showModal('modalSecretariaCertificadorPreco', 'modalCriarSecretariaCertificadorPreco')"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarSecretariaCertificadorPreco"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Editar registro de preço</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalSecretariaCertificadorPreco', 'modalEditarSecretariaCertificadorPreco')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Quantidade mensal</label>
              <input
                v-model="fastSecretariaCertificadorPrecoEditar.qtde_mensal"
                type="number"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Dias de repasse</label>
              <input
                v-model="fastSecretariaCertificadorPrecoEditar.dias_repasse_pagto"
                type="number"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Preço</label>
              <input
                v-model="fastSecretariaCertificadorPrecoEditar.preco"
                type="number"
                min="1"
                step="any"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Comissão FastEAD</label>
              <input
                v-model="fastSecretariaCertificadorPrecoEditar.comissao_fastead"
                type="number"
                min="1"
                step="any"
                class="form-control"
              >
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <button
                class="btn btn-sm btn-primary ml-1 mr-1"
                @click="atualizarSecretariaCertificadorPreco()"
              >
                Confirmar alterações
              </button>
              <button
                class="btn btn-sm btn-secondary ml-1 mr-1"
                @click="showModal('modalSecretariaCertificadorPreco', 'modalEditarSecretariaCertificadorPreco')"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalExcluirSecretariaCertificadorPreco"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Excluir registro de preço?</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalSecretariaCertificadorPreco', 'modalExcluirSecretariaCertificadorPreco')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row mt-4">
            <div class="col-12">
              <button
                class="btn btn-sm btn-danger ml-1 mr-1"
                @click="excluirSecretariaCertificadorPreco()"
              >
                Confirmar exclusão
              </button>
              <button
                class="btn btn-sm btn-secondary ml-1 mr-1"
                @click="showModal('modalSecretariaCertificadorPreco', 'modalExcluirSecretariaCertificadorPreco')"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoAdminFast",
  components: {
    Pagination
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastAdminPaginaAtual: "plataformas",
      fastPlataformasTotal: [],
      fastPlataformasFiltro: [],
      filtros: {
        nome_plataforma: "",
        status: "",
        secretaria: "",
        representante: "",
        venda: "",
        ordenar: "nome_plataforma"
      },
      model: {
        fastPlataforma: {
          id_plataforma: 0,
          nome_plataforma: "",
          url_plataforma: "",
          logo_plataforma: "",
          data_cadastro_plataforma: "",
          data_expira_teste: "",
          status: "",
          data_cancelada: "",
          id_usuario_cancelamento: "",
          banner_plataforma: "",
          plataforma_mae: "",
          plataforma_venda: "",
          plataforma_representante: "",
        },
        fastPlataformaParametro: {
          id_plataforma: 0,
          representante: "",
          id_pessoa: 0,
          biometria_token: "",
          licenca: "",
          minutos_live_mes: "",
          opentokApiKey: "",
          uservimeo: "",
          passwordvimeo: "",
          opentokApiSecret: "",
          url_plataforma: "",
          biometria_password: "",
          sso_token: "",
          sso_url: "",
          checkout_token: "",
          checkout_url: "",
          url_zoom: "",
          modulo_secretaria: "",    
          pessoa: {
            email: "",
            first_name: "",
            id_pessoa: 0,
            id_usuario: 0,
            image: "",
            last_name: "",
            status: false,
          }            
        },
      },
      // Usuários
      fastUsuariosAdmin: [],
      // Certificadores
      exibeTelasCertificadores: {
        certificadores: true,
        cursos: false
      },
      fastSecretariaCertificadores: [],
      fastSecretariaCertificadoresLoading: true,
      fastSecretariaCertificadorEditar: {
        id_pessoa: 0,
	      id_plataforma_mae: 0,
	      nome_contato: "",
	      fone_contato: "",
	      data_contrato: "",
        id_usuario_cadastro: "",
        ativo: "",
        data_ativacao_contrato: "",
        data_encerramento_contrato: "",
      },
      // Cursos
      fastSecretariaCertificadorCursos: [],
      fastSecretariaCertificadorCursosLoading: true,      
      fastSecretariaCertificadorCurso: {
        id_pessoa: 0,
        id_plataforma_mae: "",
        id_secretaria_curso: 0,
        path_portaria: ""
      },
      fastSecretariaCertificadorCursoEditar: {
        id_pessoa: 0,
        id_plataforma_mae: "",
        id_secretaria_curso: 0,
        path_portaria: "",
        uploadEmExecucao: 0
      },      
      fastSecretariaCursosTotal: [],
      fastSecretariaCursosFiltro: [],
      fastSecretariaCursosPaginacao: [],
      // Filtros
      filtroNiveis: [],
      filtroNivelEscolhido: "",
      // Upload arquivo
      optionsArquivo: {
        target:
          settings.endApiFastEad + "api/fast_plataforma_certificador_curso/upload",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          id_plataforma: this.$route.params.id_plataforma
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: [
            "pdf",
            "odt",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "gif",
            "jpg",
            "jpeg",
            "png",
          ],
        },
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept:
          "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/vnd.ms-powerpoint,image/*",
      },
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      // Estados
      fastSecretariaCertificadorEstados: [],
      fastSecretariaCertificadorEstadosLoading: true,
      fastSecretariaCertificadorEstado: {
        id_pessoa: 0,
        id_plataforma_mae: "",
        id_secretaria_curso: 0,
        uf: ""
      },
      fastSecretariaCertificadorEstadoEditar: {
        id_pessoa: 0,
        id_plataforma_mae: "",
        id_secretaria_curso: 0,
        uf: ""
      },  
      listaEstados: [
        {nome: "Acre", sigla: "AC"},
        {nome: "Alagoas", sigla: "AL"},
        {nome: "Amapá", sigla: "AP"},
        {nome: "Amazonas", sigla: "AM"},
        {nome: "Bahia", sigla: "BA"},
        {nome: "Ceará", sigla: "CE"},
        {nome: "Distrito Federal", sigla: "DF"},
        {nome: "Espírito Santo", sigla: "ES"},
        {nome: "Goiás", sigla: "GO"},
        {nome: "Maranhão", sigla: "MA"},
        {nome: "Mato Grosso", sigla: "MT"},
        {nome: "Mato Grosso do Sul", sigla: "MS"},
        {nome: "Minas Gerais", sigla: "MG"},
        {nome: "Pará", sigla: "PA"},
        {nome: "Paraíba", sigla: "PB"},
        {nome: "Paraná", sigla: "PR"},
        {nome: "Pernambuco", sigla: "PE"},
        {nome: "Piauí", sigla: "PI"},
        {nome: "Rio de Janeiro", sigla: "RJ"},
        {nome: "Rio Grande do Norte", sigla: "RN"},
        {nome: "Rio Grande do Sul", sigla: "RS"},
        {nome: "Rondônia", sigla: "RO"},
        {nome: "Roraima", sigla: "RR"},
        {nome: "Santa Catarina", sigla: "SC"},
        {nome: "São Paulo", sigla: "SP"},
        {nome: "Sergipe", sigla: "SE"},
        {nome: "Tocantins", sigla: "TO"}
      ],
      // Preços
      fastSecretariaCertificadorPrecos: [],
      fastSecretariaCertificadorPrecosLoading: true,
      fastSecretariaCertificadorPreco: {
        id_certificador_preco: "",
        id_pessoa: 0,
        id_plataforma_mae: "",
        id_secretaria_curso: 0,
        qtde_mensal: "",
        preco: "",
        comissao_fastead: "",
        dias_repasse_pagto: ""
      },
      fastSecretariaCertificadorPrecoEditar: {
        id_certificador_preco: "",
        id_pessoa: 0,
        id_plataforma_mae: "",
        id_secretaria_curso: 0,
        qtde_mensal: "",
        preco: "",
        comissao_fastead: "",
        dias_repasse_pagto: ""
      },  
    };
  },
  mounted: function () {
    this.$store.state.fastUsuarioAdminFast = this.getFastSessao(settings.fastSessaoUsuarioAdminFast);
    if (this.$store.state.fastUsuarioAdminFast) {
      console.log("Admin fast acessando")
      this.fastAdminNavegacao(this.fastAdminPaginaAtual)
      
    } else {
      this.exibeToasty("Sem permissão", "error");
      this.$store.state.fastCarregando = false;
    }
  },
  methods: {
    fastAdminNavegacao(pagina) {
      this.fastAdminPaginaAtual = pagina 
      switch (pagina) {
        case "plataformas":
          this.$store.state.fastCarregando = true;
          this.getTodasPlataformas()
          break;
        case "usuarios":
          this.$store.state.fastCarregando = true;
          this.getUsuariosFast()
          break;
        case "certificadores":
          this.getSecretariaTodosCertificadores()
          break;
        default:
          break;
      }
    },
    // Plataformas
    async getTodasPlataformas(){
      this.fastPlataformasTotal = []
      this.fastPlataformasFiltro = []
      this.$store.state.fastCarregando = true;
      this.promiseGetFastApi("api/fast_plataforma_usuario/lista_todas_plataformas").then(obj => {     
        obj.forEach(p => {
          p.nome_plataforma = p.nome_plataforma.trim()
          p.data_cadastro_plataforma = new Date(p.data_cadastro_plataforma)
          if (p.plataforma_venda != 'S') p.plataforma_venda = 'N'
          if (p.plataforma_representante != 'S') p.plataforma_representante = 'N'
          if (p.modulo_secretaria) p.modulo_secretaria = 'S' 
          else p.modulo_secretaria = 'N'
        })  

        this.fastPlataformasTotal = obj
        this.fastPlataformasFiltro = obj
        this.aplicaFiltroGeral()
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        console.log(e);
        this.$store.state.fastCarregando = false;
      })
    },    
    aplicaFiltroGeral() {
      // Filtra pelo nome
      if (this.filtros.nome_plataforma.length > 2) {
        this.fastPlataformasFiltro = this.fastPlataformasTotal.filter((e) => {
          if (e.nome_plataforma.toLowerCase().indexOf(this.filtros.nome_plataforma.toLowerCase()) != "-1") return e;
        });
      } else {
        this.fastPlataformasFiltro = this.fastPlataformasTotal
      }

      if (this.filtros.status) {
        this.fastPlataformasFiltro = this.fastPlataformasFiltro.filter((e) => { return (e.status.toLowerCase() == this.filtros.status.toLowerCase()); });   
      }

      if (this.filtros.secretaria) {
        if (this.filtros.secretaria == "S") this.fastPlataformasFiltro = this.fastPlataformasFiltro.filter((e) => { if (e.modulo_secretaria == 'S') return true });   
        else if (this.filtros.secretaria == "N") this.fastPlataformasFiltro = this.fastPlataformasFiltro.filter((e) => { if (e.modulo_secretaria == 'N') return true });   
      }

      if (this.filtros.representante) {
        if (this.filtros.representante == "S") this.fastPlataformasFiltro = this.fastPlataformasFiltro.filter((e) => { if (e.plataforma_representante == 'S') return true });   
        else if (this.filtros.representante == "N") this.fastPlataformasFiltro = this.fastPlataformasFiltro.filter((e) => { if (e.plataforma_representante == 'N') return true });   
      }

      if (this.filtros.venda) {
        if (this.filtros.venda == "S") this.fastPlataformasFiltro = this.fastPlataformasFiltro.filter((e) => { if (e.plataforma_venda == 'S') return true });   
        else if (this.filtros.venda == "N") this.fastPlataformasFiltro = this.fastPlataformasFiltro.filter((e) => { if (e.plataforma_venda == 'N') return true });   
      }

      if (this.filtros.ordenar == "data_cadastro_plataforma_asc") {
        this.fastPlataformasFiltro.sort((a, b) => {
          var c = new Date(a.data_cadastro_plataforma);
          var d = new Date(b.data_cadastro_plataforma);
          return d-c;});
      }
      else if (this.filtros.ordenar == "data_cadastro_plataforma_desc") {
        this.fastPlataformasFiltro.sort((a, b) => {
          var c = new Date(a.data_cadastro_plataforma);
          var d = new Date(b.data_cadastro_plataforma);
          return c-d;});
      }
      else  this.fastPlataformasFiltro.sort((a, b) => a.nome_plataforma.localeCompare(b.nome_plataforma))  

    },
    limparFiltro() {
      this.filtros = {
        nome_plataforma: "",
        status: "",
        secretaria: "",
        representante: "",
        venda: "",
        ordenar: "nome_plataforma"
      }
    },
    exibeModalEditarPlataforma(p) {
      console.log(p)
      
      this.model.fastPlataforma.id_plataforma = p.id_plataforma ? p.id_plataforma : ""

      this.model.fastPlataforma.status = p.status ? p.status : ""
      this.model.fastPlataforma.plataforma_venda = p.plataforma_venda ? p.plataforma_venda : ""
      this.model.fastPlataforma.plataforma_representante = p.plataforma_representante ? p.plataforma_representante : ""
      this.model.fastPlataforma.nome_plataforma = p.nome_plataforma ? p.nome_plataforma : ""

      this.model.fastPlataformaParametro.id_pessoa = p.id_pessoa ? p.id_pessoa : ""      
      this.model.fastPlataformaParametro.sso_token = p.sso_token ? p.sso_token : ""
      this.model.fastPlataformaParametro.sso_url = p.sso_url ? p.sso_url : ""
      this.model.fastPlataformaParametro.checkout_token = p.checkout_token ? p.checkout_token : ""
      this.model.fastPlataformaParametro.checkout_token = p.checkout_token ? p.checkout_token : ""
      this.model.fastPlataformaParametro.modulo_secretaria = p.modulo_secretaria ? p.modulo_secretaria : ""

      if (p.id_pessoa) {
        this.promiseGetFastApi("api/fast_plataforma_usuario/lista_pessoa", "id_pessoa=" + p.id_pessoa).then(obj => {  
          if (JSON.parse(obj).id_pessoa) {
            this.model.fastPlataformaParametro.pessoa = JSON.parse(obj)
          } else {
            this.exibeToasty("Erro ao buscar pessoa", "error");
          }          
          this.$store.state.fastCarregando = false;
          this.showModal('modalEditarPlataforma')
        }).catch(e => {
          console.log(e);
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
          this.showModal('modalEditarPlataforma')
        })
      } else {
        this.model.fastPlataformaParametro.pessoa.email = ""
        this.model.fastPlataformaParametro.pessoa.first_name = ""
        this.model.fastPlataformaParametro.pessoa.id_pessoa = ""
        this.model.fastPlataformaParametro.pessoa.id_usuario = ""
        this.model.fastPlataformaParametro.pessoa.image = ""
        this.model.fastPlataformaParametro.pessoa.last_name = ""
        this.model.fastPlataformaParametro.pessoa.status = ""
        this.showModal('modalEditarPlataforma')
      }
      

      
    },
    async editarFastPlataforma() {
      const model = {
        fast_plataforma: {
          id_plataforma: this.model.fastPlataforma.id_plataforma,
          status: this.model.fastPlataforma.status,
          plataforma_venda: this.model.fastPlataforma.plataforma_venda,
          plataforma_representante: this.model.fastPlataforma.plataforma_representante
        },
        fast_plataforma_parametro: {
          id_pessoa: this.model.fastPlataformaParametro.pessoa.id_pessoa ? this.model.fastPlataformaParametro.pessoa.id_pessoa : 0,
          sso_token: this.model.fastPlataformaParametro.sso_token,
          sso_url: this.model.fastPlataformaParametro.sso_url,
          checkout_token: this.model.fastPlataformaParametro.checkout_token,
          checkout_url: this.model.fastPlataformaParametro.checkout_url,
          modulo_secretaria: this.model.fastPlataformaParametro.modulo_secretaria == 'S' ? true : false
        }
      }
      console.log("this.model", model)
      this.$store.state.fastCarregando = true;
        this.promisePostFastApi(model, "api/fast_plataforma_usuario/atualiza_admin").then(obj => {
          if (obj.length) {
            this.exibeToasty("Salvo com sucesso", "success");
            this.hideModal("modalEditarPlataforma");
            obj.forEach(p => {
              p.nome_plataforma = p.nome_plataforma.trim()
              p.data_cadastro_plataforma = new Date(p.data_cadastro_plataforma)
              if (p.plataforma_venda != 'S') p.plataforma_venda = 'N'
              if (p.plataforma_representante != 'S') p.plataforma_representante = 'N'
              if (p.modulo_secretaria) p.modulo_secretaria = 'S' 
              else p.modulo_secretaria = 'N'
            })  
            this.fastPlataformasTotal = obj
            this.fastPlataformasFiltro = obj

            this.limparFiltro()
            this.filtros.nome_plataforma = this.model.fastPlataforma.nome_plataforma
            this.aplicaFiltroGeral()
          } else {
            this.exibeToasty("Erro ao salvar alterações", "error");
          }
          this.$store.state.fastCarregando = false;
          
        }).catch(e => {
          this.exibeToasty("Erro ao salvar alterações", "error");
          this.$store.state.fastCarregando = false;
        }
        );
    },
    async getPessoaResponsavel(){
      if (/\S+@\S+\.\S+/.test(this.model.fastPlataformaParametro.pessoa.email.trim())) {
        this.promiseGetFastApi("api/fast_plataforma_usuario/lista_pessoas", "email=" + this.model.fastPlataformaParametro.pessoa.email.trim()).then(obj => {     
          console.log("getPessoaResponsavel", obj);     
          if (obj) {       
            this.model.fastPlataformaParametro.id_pessoa = JSON.parse(obj).id_pessoa     
            this.model.fastPlataformaParametro.pessoa.id_pessoa = JSON.parse(obj).id_pessoa
            this.model.fastPlataformaParametro.pessoa.first_name = JSON.parse(obj).first_name
            this.model.fastPlataformaParametro.pessoa.last_name = JSON.parse(obj).last_name
            this.model.fastPlataformaParametro.pessoa.image = JSON.parse(obj).image
            this.model.fastPlataformaParametro.pessoa.email = JSON.parse(obj).email
          } else {
            this.exibeToasty("Pessoa não encontrada", "error");
          }
        }).catch(e => {
          console.log(e);
        })
      } else {
        this.exibeToasty("Informe um email válido", "error");
      }
    },
    cancelarFastPlataforma() {
      this.hideModal("modalEditarPlataforma");
      this.getTodasPlataformas()
    },    
    // Usuários
    async getUsuariosFast() {
      this.promiseGetFastApi("api/fast_plataforma_usuario/lista_usuarios_admin").then(obj => {     
        console.log("getUsuariosFast", obj);   
        this.fastUsuariosAdmin = obj
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        console.log(e);
        this.$store.state.fastCarregando = false;
      })
    },
    // Certificadores
    getSecretariaTodosCertificadores(){
      this.fastSecretariaCertificadoresLoading = true
      this.promiseGetFastApi("api/fast_plataforma_certificador/admin/lista", "").then(obj => {
        console.log("getSecretariaTodosCertificadores", obj);
        if (obj.length) this.fastSecretariaCertificadores = obj;
        else
        this.fastSecretariaCertificadores = []

        this.fastSecretariaCertificadoresLoading = false
      }).catch(e => {
        this.exibeToasty("Não foi possível listar o certificadores", "error");
        this.fastSecretariaCertificadoresLoading = false
      });
    },
    exibeModalEditarSecretariaCertificador(certificador) {
      if (certificador.data_contrato) certificador.data_contrato = certificador.data_contrato.split("T")[0]
      if (certificador.data_ativacao_contrato) certificador.data_ativacao_contrato = certificador.data_ativacao_contrato.split("T")[0]
      if (certificador.data_encerramento_contrato) certificador.data_encerramento_contrato = certificador.data_encerramento_contrato.split("T")[0]
      this.fastSecretariaCertificadorEditar = certificador;

      console.log("fastSecretariaCertificadorEditar", certificador)
      this.showModal("modalEditarSecretariaCertificador");
    },
    async editarSecretariaCertificador() {
       this.$store.state.fastCarregando = true;
        this.promisePostFastApi(this.fastSecretariaCertificadorEditar, "api/fast_plataforma_certificador/admin/atualiza").then(res => {
          console.log("editarSecretariaCertificador", res)
          this.exibeToasty("Salvo com sucesso", "success");
          this.$store.state.fastCarregando = false;
          this.hideModal("modalEditarSecretariaCertificador");
        }).catch(e => {
          this.exibeToasty("Erro ao salvar alterações", "error");
          this.$store.state.fastCarregando = false;
        }
        );
    },
    exibeModalExcluirSecretariaCertificadorCurso(curso) {
      this.fastSecretariaCertificadorCursoEditar = curso
      this.showModal('modalExcluirSecretariaCertificadorCurso')
    },
    async excluirSecretariaCertificadorCurso(){
      this.fastSecretariaCertificadorCursosLoading = true
      this.fastSecretariaCertificadorCursos = []
      this.promisePostFastApi(this.fastSecretariaCertificadorCursoEditar, "api/fast_plataforma_certificador_curso/exclui").then(res => {
        this.exibeToasty("Curso excluído com sucesso", "success");  
        this.hideModal('modalExcluirSecretariaCertificadorCurso')
        this.fastSecretariaCertificadorCursos = res
        this.fastSecretariaCertificadorCursosLoading = false;
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error");
        this.fastSecretariaCertificadorCursosLoading = false;
      });
    },
    exibeModalEditarCursos(certificador){
      this.fastSecretariaCertificadorEditar = certificador;  
      this.fastSecretariaCertificadorCursosLoading = true
      //this.showModal('modalSecretariaCertificadorCursos')
      this.exibeTelasCertificadores.certificadores = false
      this.exibeTelasCertificadores.cursos = true
      this.getSecretariaCursos(certificador.id_plataforma_mae);
      this.promiseGetFastApi("api/fast_plataforma_certificador_curso/lista", "id_plataforma=" + certificador.id_plataforma_mae + "&id_pessoa=" + certificador.id_pessoa).then(res => {
        this.fastSecretariaCertificadorCursos = res
        this.fastSecretariaCertificadorCursosLoading = false
      }).catch(e => {
        this.fastSecretariaCertificadorCursosLoading = false
        this.exibeToasty(e, "error")
      });
    },
    exibeModalSecretariaCertificadorAnexo(curso) {
      this.fastSecretariaCertificadorCursoEditar = curso
      this.showModal('modalSecretariaCertificadorAnexo')      
    },
    getSecretariaCursos(id_plataforma){
      this.promiseGetFastApi("api/fast_secretaria_curso/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=0").then(res => {
        console.log(res)
        if (res.length) {  
          this.fastSecretariaCursosTotal = res;
          this.fastSecretariaCursosFiltro = res;
          this.carregaFiltroCursos(res);
        } else {
          this.fastSecretariaCursosTotal = [];
          this.fastSecretariaCursosFiltro = [];
        }
        
        this.$store.state.fastCarregando = false;
      }).catch(e => {
        console.log(e);
      })
    },
    retornaCursoNivel(nivel) {
      switch (nivel) {
        case 'F':
          return 'Ensino fundamental'
          break;
        case 'E':
          return 'Ensino médio'
          break;
        case 'J':
          return 'EJA'
          break;
        case 'T':
          return 'Técnico'
          break;        
        case 'S':
          return 'Ensino superior'
          break;
        case 'P':
          return 'Pós-graduação'
          break;
        case 'M':
          return 'Mestrado'
          break;
        case 'D':
          return 'Doutorado'
          break;        
        default:
          break;
      }
    },
    filtroNomeCurso(e) {
      let text = e.target.value;
      this.fastSecretariaCursosFiltro = this.fastSecretariaCursosTotal.filter(
        (e) => {
          return (
            e.nome_curso.toLowerCase().indexOf(text.toLowerCase()) != "-1"
          );
        }
      );
    },
    aplicaFiltroCursos() {
      this.fastSecretariaCursosFiltro = this.fastSecretariaCursosTotal.filter(
        (e) => {
          if (this.filtroNivelEscolhido) {
            return e.nivel == this.filtroNivelEscolhido;
          } else {
            return true;
          }
        }
      );
    },
    carregaFiltroCursos(obj) {
      this.filtroNiveis = []      
      obj.forEach((e) => {
        this.filtroNiveis.push(e.nivel);
      });
      // Remove valores duplicados do array
      this.filtroNiveis = this.filtroNiveis.filter(
        (a, b) => this.filtroNiveis.indexOf(a) === b
      );
    },
    async inserirSecretariaCertificadorCurso(curso) {
      this.fastSecretariaCertificadorCurso.id_secretaria_curso = curso.id_secretaria_curso
      this.fastSecretariaCertificadorCurso.id_pessoa = this.fastSecretariaCertificadorEditar.id_pessoa
      this.fastSecretariaCertificadorCurso.id_plataforma_mae = this.fastSecretariaCertificadorEditar.id_plataforma_mae
      this.fastSecretariaCertificadorCurso.path_portaria = "0"
      this.fastSecretariaCertificadorCursosLoading = true
      this.promisePostFastApi(this.fastSecretariaCertificadorCurso, "api/fast_plataforma_certificador_curso/insere").then(res => {
        this.fastSecretariaCertificadorCursos = res
        this.fastSecretariaCertificadorCursosLoading = false
        this.hideModal('modalMeusCursos')
      }).catch(e => {
        this.fastSecretariaCertificadorCursosLoading = false
        this.exibeToasty(e, "error")
      });
    },
    verificaCursoJaInserido(id_secretaria_curso){
      let count = 0      
      console.log("id_secretaria_curso", id_secretaria_curso, this.fastSecretariaCertificadorCursos)
      this.fastSecretariaCertificadorCursos.forEach(c => {
        if (c.id_secretaria_curso == id_secretaria_curso) count++
      })
      return count
    },
    // Estados
    exibeModalSecretariaCertificadorEstado(curso) {
      this.fastSecretariaCertificadorCursoEditar = curso
      this.showModal('modalSecretariaCertificadorEstado')
      this.fastSecretariaCertificadorEstadosLoading = true
      this.fastSecretariaCertificadorEstados = []
      this.promiseGetFastApi("api/fast_plataforma_certificador_estado/lista", "id_plataforma=" + this.fastSecretariaCertificadorCursoEditar.id_plataforma_mae + "&id_pessoa=" + this.fastSecretariaCertificadorCursoEditar.id_pessoa + "&id_secretaria_curso=" + curso.id_secretaria_curso).then(res => {
        console.log("exibeModalSecretariaCertificadorEstado", res)
        this.fastSecretariaCertificadorEstados = res
        this.fastSecretariaCertificadorEstadosLoading = false
      }).catch(e => {
        this.fastSecretariaCertificadorEstadosLoading = false
        this.exibeToasty(e, "error")
      });
    },    
    async inserirSecretariaCertificadorEstado(sigla) {
      this.fastSecretariaCertificadorCursoEditar.uf = sigla
      this.promisePostFastApi(this.fastSecretariaCertificadorCursoEditar, "api/fast_plataforma_certificador_estado/insere").then(res => {
        this.fastSecretariaCertificadorEstados = res
        this.fastSecretariaCertificadorCursos.forEach(c => {
          if (c.id_secretaria_curso == this.fastSecretariaCertificadorCursoEditar.id_secretaria_curso) {
            if (this.fastSecretariaCertificadorCursoEditar.uf == '00') c.total_estados = 27
            else
            c.total_estados++
          }
        })
        if (this.fastSecretariaCertificadorCursoEditar.uf == '00') this.showModal('modalSecretariaCertificadorEstado', 'modalListaEstados')
      }).catch(e => {
        this.exibeToasty(e, "error")
      });
    },
    verificaEstadoJaInserido(sigla){
      let count = 0      
      this.fastSecretariaCertificadorEstados.forEach(c => {
        if (c.uf == sigla) count++
      })
      return count
    },
    retornaEstadoNome(sigla) {
      let estado = ""
      this.listaEstados.forEach(e => { if (e.sigla == sigla) estado = e.nome })
      return estado
    },
    async excluirSecretariaCertificadorEstado(sigla) {     
      this.fastSecretariaCertificadorCursoEditar.uf = sigla
      console.log("this.fastSecretariaCertificadorCursoEditar", this.fastSecretariaCertificadorCursoEditar)
      this.fastSecretariaCertificadorEstadosLoading = true
      this.promisePostFastApi(this.fastSecretariaCertificadorCursoEditar, "api/fast_plataforma_certificador_estado/exclui").then(res => {  
        console.log(res)      
        this.exibeToasty("Estado excluído com sucesso", "success");  
        this.fastSecretariaCertificadorEstados = res
        this.fastSecretariaCertificadorEstadosLoading = false 
        
        this.fastSecretariaCertificadorCursos.forEach(c => {
          if (c.id_secretaria_curso == this.fastSecretariaCertificadorCursoEditar.id_secretaria_curso) {
            c.total_estados--
          }
        })
      }).catch(e => {
        this.exibeToasty("Erro ao excluir", "error");
        this.fastSecretariaCertificadorEstadosLoading = false;
      });
    },
    // Preço
    exibeModalSecretariaCertificadorPreco(curso) {
      this.fastSecretariaCertificadorPrecosLoading = true
      this.fastSecretariaCertificadorCursoEditar = curso
      this.showModal('modalSecretariaCertificadorPreco')      
      this.fastSecretariaCertificadorPrecos = []
      this.promiseGetFastApi("api/fast_plataforma_certificador_preco/lista", "id_plataforma=" + this.fastSecretariaCertificadorCursoEditar.id_plataforma_mae + "&id_pessoa=" + this.fastSecretariaCertificadorCursoEditar.id_pessoa + "&id_secretaria_curso=" + this.fastSecretariaCertificadorCursoEditar.id_secretaria_curso).then(res => {
        this.fastSecretariaCertificadorPrecos = res
        this.fastSecretariaCertificadorPrecosLoading = false
      }).catch(e => {
        this.fastSecretariaCertificadorPrecosLoading = false
        this.exibeToasty(e, "error")
      });
    },
    async insereSecretariaCertificadorPreco() {
      this.fastSecretariaCertificadorPreco.id_pessoa = this.fastSecretariaCertificadorCursoEditar.id_pessoa
      this.fastSecretariaCertificadorPreco.id_plataforma_mae = this.fastSecretariaCertificadorCursoEditar.id_plataforma_mae
      this.fastSecretariaCertificadorPreco.id_secretaria_curso = this.fastSecretariaCertificadorCursoEditar.id_secretaria_curso

      let erros = []
      if (!this.fastSecretariaCertificadorPreco.qtde_mensal) erros.push("A quantidade mensal é obrigatória")
      if (!this.fastSecretariaCertificadorPreco.preco) erros.push("O preço é obrigatório")
      if (!this.fastSecretariaCertificadorPreco.comissao_fastead) erros.push("A comissão é obrigatória")

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.promisePostFastApi(this.fastSecretariaCertificadorPreco, "api/fast_plataforma_certificador_preco/insere").then(res => {  
          console.log("insereSecretariaCertificadorPreco", res)      
          this.fastSecretariaCertificadorPrecos = res
          this.exibeToasty("Preço cadastrado com sucesso", "success");  
          this.showModal('modalSecretariaCertificadorPreco', 'modalCriarSecretariaCertificadorPreco') 

          this.fastSecretariaCertificadorCursos.forEach(c => {
            if (c.id_secretaria_curso == this.fastSecretariaCertificadorPreco.id_secretaria_curso) {
              c.total_precos++
            }
          })
          
          this.fastSecretariaCertificadorPreco = {
            id_certificador_preco: "",
            id_pessoa: 0,
            id_plataforma_mae: "",
            id_secretaria_curso: 0,
            qtde_mensal: "",
            preco: "",
            comissao_fastead: "",
            dias_repasse_pagto: ""
          }

        }).catch(e => {
          this.exibeToasty("Erro ao inserir", "error");
        });
      }
    },
    exibeModalEditarSecretariaCertificadorPreco(preco){
      this.fastSecretariaCertificadorPrecoEditar = preco
      this.showModal('modalEditarSecretariaCertificadorPreco', 'modalSecretariaCertificadorPreco')
    },
    async atualizarSecretariaCertificadorPreco() {
      let erros = []
      if (!this.fastSecretariaCertificadorPrecoEditar.qtde_mensal) erros.push("A quantidade mensal é obrigatória")
      if (!this.fastSecretariaCertificadorPrecoEditar.preco) erros.push("O preço é obrigatório")
      if (!this.fastSecretariaCertificadorPrecoEditar.comissao_fastead) erros.push("A comissão é obrigatória")

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.promisePostFastApi(this.fastSecretariaCertificadorPrecoEditar, "api/fast_plataforma_certificador_preco/atualiza").then(res => {  
          console.log("atualizarSecretariaCertificadorPreco", res)      
          this.fastSecretariaCertificadorPrecos = res
          this.exibeToasty("Preço atualizado com sucesso", "success");  
          this.showModal('modalSecretariaCertificadorPreco', 'modalEditarSecretariaCertificadorPreco') 
          
          this.fastSecretariaCertificadorPrecoEditar = {
            id_certificador_preco: "",
            id_pessoa: 0,
            id_plataforma_mae: "",
            id_secretaria_curso: 0,
            qtde_mensal: "",
            preco: "",
            comissao_fastead: "",
            dias_repasse_pagto: ""
          }
          
        }).catch(e => {
          this.exibeToasty("Erro ao atualizar", "error");
        });
      }
    },
    exibeModalExcluirSecretariaCertificadorPreco(preco){
      this.fastSecretariaCertificadorPrecoEditar = preco
      this.showModal('modalExcluirSecretariaCertificadorPreco', 'modalSecretariaCertificadorPreco')
    },
    async excluirSecretariaCertificadorPreco() {
      this.promisePostFastApi(this.fastSecretariaCertificadorPrecoEditar, "api/fast_plataforma_certificador_preco/exclui").then(res => {  
          console.log("excluirSecretariaCertificadorPreco", res)      
          this.fastSecretariaCertificadorPrecos = res
          this.exibeToasty("Preço excluído com sucesso", "success");  
          this.showModal('modalSecretariaCertificadorPreco', 'modalExcluirSecretariaCertificadorPreco') 
          
          this.fastSecretariaCertificadorCursos.forEach(c => {
            if (c.id_secretaria_curso == this.fastSecretariaCertificadorPrecoEditar.id_secretaria_curso) {
              c.total_precos--
            }
          })

          this.fastSecretariaCertificadorPrecoEditar = {
            id_certificador_preco: "",
            id_pessoa: 0,
            id_plataforma_mae: "",
            id_secretaria_curso: 0,
            qtde_mensal: "",
            preco: "",
            comissao_fastead: "",
            dias_repasse_pagto: ""
          }

        }).catch(e => {
          this.exibeToasty("Erro ao excluir", "error");
        });
    },
  },
};
</script>

<style></style>
